<template>
  <div class="ecommerce-application">
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h4 class="mb-0">
          {{$t('learning_path.select_contents')}}
        </h4>
        <small class="text-muted">{{$t('learning_path.select_contents_description')}}</small>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="3" class="d-lg-block d-none">
        <left-filter-sidebar 
          @contents-hierarchy-selected="selectedContentsHierarchy"
          @search="searchContents"
          @template-changed="handleTemplateChange"
          :contentsHierarchy="$store.getters['learningPaths/getContentsHierarchy']"
          :loading="loading"
          :filterOptions="$store.getters['learningPaths/getContentFilterOptions']"/>
      </b-col>
      <b-col>
        <contents-selector-container 
          :loading="loading"
          :isReport="false"
          :searchword.sync="searchword"
          :contentsHierarchy="$store.getters['learningPaths/getContentsHierarchy']"
          :filterOptions="$store.getters['learningPaths/getContentFilterOptions']"
          :contents="$store.getters['learningPaths/getFilteredContents']"
          :route_contents="$store.getters['learningPaths/getRoute'].contents"
          :sortedContents="sortedContents"
          :showSearchbar.sync="showSearchbar"
          @toggle-content-in-list="toggleContentInList"
          @select-all-content="selectAllContent"
          @search="searchContents"
          @contents-hierarchy-selected="selectedContentsHierarchy"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LeftFilterSidebar from'../components/LeftFilterSidebar.vue'
import ContentsSelectorContainer from '../components/ContentsSelectorContainer.vue'
import { fetchContentSearch } from '@/api/learningpaths'
import { 
  BRow, BCol, BSpinner,
} from 'bootstrap-vue'
export default {
  components:{
    BRow, BCol, BSpinner,
    LeftFilterSidebar,
    ContentsSelectorContainer,
  },
  data(){
    return {
      showSearchbar: true,
      searchword:'',
      loading: false,
      sortedContents:[]
    }
  },
  methods:{
    selectedContentsHierarchy(contents){
      if(contents.length == 0){
        this.sortedContents = []
      } else {
        this.sortedContents = contents
      }
      this.$store.commit('learningPaths/setFilteredContents', contents)
    },
    async searchContents(){
      //// console.log("buscar contenidos con esta palabra:", this.searchword, "y con los criterios de filter options seleccionados")
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'q': this.searchword,
        'token': this.$store.getters['user/getUserData'].sso,
      }
      let filters = this.$store.getters['learningPaths/getContentFilterOptions']
      // console.log(filters)
      filters.forEach(filter_category => {
        let selected_options = []
        if(filter_category.name=='age'){

          let ages = []
          filter_category.options.map(op => {
              if(op.selected){
                  let ages_arr = op.id.split('-')
                  ages_arr.map(age => {
                      ages.push(parseInt(age))
                  })
              }
          })
          let min = Math.min(...ages)  
          let max = Math.max(...ages)
          
          selected_options = [min, max]

        } else {
          filter_category.options.forEach(opt => {
            if(opt.selected){
              selected_options.push(opt.id)
            }
          });
        }
        data[filter_category.name] = selected_options
      });
        //// console.log(data, "SEARCH")
        this.loading = true

      await this.$http.post(fetchContentSearch, data).then( response => {
        // console.log(response)
        if(response.status == 200){

          if(response.data.length == 0){
            this.sortedContents = []
          } else {
            this.sortedContents = response.data
          }
          
          this.$store.commit('learningPaths/setFilteredContents', response.data)  
          this.loading = false

        } else {
          this.makeToast('danger', this.$t('Error'),this.$t('error_msg'));
          this.loading = false
        }
      }).catch(error => {
        this.loading = false
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    selectAllContent(contents){
      let route_contents = Object.values(contents)
      // console.log("all contents TAB LP Contents", route_contents)
      this.$store.commit('learningPaths/setRouteContents', route_contents)
    },
    toggleContentInList(content){
      let route_contents = [...this.$store.getters['learningPaths/getRoute'].contents]
      const index = route_contents.findIndex(object => {
        return object.id === content.id;
      });

      if(index < 0){
        route_contents.push(content)
      } else {
        route_contents.splice(index, 1)
      }
      this.$store.commit('learningPaths/setRouteContents', route_contents)
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    handleTemplateChange(){
      this.showSearchbar = !this.showSearchbar
    },
  }
}
</script>

<style lang="scss">
  @import '~@core/scss/base/pages/app-ecommerce.scss';

</style>